import { extendTheme, ThemeConfig } from '@chakra-ui/react'

interface GlobalProps {
  colorMode: string
  theme: Record<string, unknown>
}

const config: ThemeConfig = {
  useSystemColorMode: false,
  initialColorMode: 'light',
}

const colors = {
  brand: {
    50: '#ffe2ef',
    100: '#ffb3cd',
    200: '#fd82ab',
    300: '#fb5089',
    400: '#f92068',
    500: '#df064e',
    600: '#af013d',
    700: '#7e002c',
    800: '#4d0019',
    900: '#20000a',
  },
}

const styles = {
  global: ({ colorMode }: GlobalProps) => ({
    body: {
      backgroundColor: colorMode === 'dark' ? 'gray.900' : 'white',
      overscrollBehavior: 'none',
    },
    // a: {
    //     color: colorMode === "dark" ? "brand.300" : "brand.400",
    // },
  }),
}

const theme = extendTheme({ config, colors, styles })

export default theme
