/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

import React from 'react'

import Layout from './src/components/layout'
import AppContext from './src/context'

export const wrapPageElement = ({ element }) => {
  return <Layout>{element}</Layout>
}

export const wrapRootElement = ({ element }) => {
  return <AppContext>{element}</AppContext>
}
