import React from 'react'
import { Box } from '@chakra-ui/react'

import { useUser } from '~context/user'

const PartnerBanner: React.FC = () => {
  const { user } = useUser()
  const { partner } = user

  return (
    <Box
      top={0}
      zIndex={2}
      w="100vw"
      pos="fixed"
      display="flex"
      justifyContent="center"
      borderTop="1px solid"
      borderColor="brand.300"
    >
      <Box
        px={3}
        mt="-1px"
        bgColor="brand.300"
        color="white"
        fontSize="xs"
        fontWeight="bold"
        borderBottomRadius="4px"
        textTransform="uppercase"
      >
        {partner?.organization}
      </Box>
    </Box>
  )
}

export default PartnerBanner
