import React, { createContext, useState, useEffect, useContext } from 'react'
import throttle from 'lodash/throttle'

interface IScrollContext {
  x: number
  y: number
  w: number
  h: number
  isBottom: boolean
}

function getWindowScroll(): IScrollContext {
  if (typeof window !== 'undefined') {
    const { innerWidth, innerHeight, scrollX, scrollY } = window
    const { body } = document

    return {
      x: scrollX,
      y: scrollY,
      w: innerWidth,
      h: innerHeight,
      isBottom:
        body.offsetHeight > innerHeight
          ? innerHeight + scrollY > body.offsetHeight - 1
          : false,
    }
  }

  return { x: 0, y: 0, w: 0, h: 0, isBottom: false }
}

export const ScrollContext = createContext<IScrollContext>(getWindowScroll())

export const ScrollProvider: React.FC = ({ children }) => {
  const [windowScroll, setWindowScroll] = useState(getWindowScroll())

  useEffect(() => {
    const handleScroll = () => {
      setWindowScroll(getWindowScroll())
    }
    const handleScrollThrottled = throttle(handleScroll, 300)

    window.addEventListener('scroll', handleScrollThrottled)
    return () => {
      window.removeEventListener('scroll', handleScrollThrottled)
    }
  }, [])

  return (
    <ScrollContext.Provider value={windowScroll}>
      {children}
    </ScrollContext.Provider>
  )
}

export const useScroll = () => useContext(ScrollContext)
