import React from 'react'
import { Link as GatsbyLink } from 'gatsby'
import { useMatch } from '@reach/router'
import {
  Box,
  Icon,
  Text,
  Link,
  Button,
  Center,
  useColorModeValue,
} from '@chakra-ui/react'
import {
  HiOutlineViewGrid,
  HiOutlineTicket,
  HiOutlineGlobe,
  HiOutlineUser,
  HiOutlineOfficeBuilding,
  HiOutlineQuestionMarkCircle,
} from 'react-icons/hi'
import { IconType } from 'react-icons'

import Logo from '~components/layout/logofull'

const Sidebar: React.FC = () => {
  return (
    <Box
      py={4}
      h="100%"
      rounded="2xl"
      display="flex"
      flexDir="column"
      transition="all .2s"
    >
      <Box flexGrow={1}>
        <Center mt={2} mb={8}>
          <Logo />
        </Center>
        <SidebarItem
          link="/"
          match="/"
          label="Dashboard"
          iconColor="brand.300"
          icon={HiOutlineViewGrid}
        />
        <SidebarItem
          link="/orders"
          match="/orders/*"
          label="Orders"
          iconColor="#c3178c"
          icon={HiOutlineTicket}
        />
        <SidebarItem
          link="/events"
          match="/events/*"
          label="Events"
          iconColor="#5553a2"
          icon={HiOutlineGlobe}
        />
        <SidebarItem
          link="/reservations"
          match="/reservations/*"
          label="Reservations"
          iconColor="#853493"
          icon={HiOutlineOfficeBuilding}
        />
        <SidebarItem
          link="/account"
          match="/account"
          label="Account"
          iconColor="#01b7eb"
          icon={HiOutlineUser}
        />
      </Box>

      <Box
        p={4}
        bgColor="brand.200"
        rounded="xl"
        bgImage={`url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='80' height='80' viewBox='0 0 80 80'%3E%3Cg fill='%23fafafa' fill-opacity='0.1'%3E%3Cpath fill-rule='evenodd' d='M11 0l5 20H6l5-20zm42 31a3 3 0 1 0 0-6 3 3 0 0 0 0 6zM0 72h40v4H0v-4zm0-8h31v4H0v-4zm20-16h20v4H20v-4zM0 56h40v4H0v-4zm63-25a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm10 0a3 3 0 1 0 0-6 3 3 0 0 0 0 6zM53 41a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm10 0a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm10 0a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm-30 0a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm-28-8a5 5 0 0 0-10 0h10zm10 0a5 5 0 0 1-10 0h10zM56 5a5 5 0 0 0-10 0h10zm10 0a5 5 0 0 1-10 0h10zm-3 46a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm10 0a3 3 0 1 0 0-6 3 3 0 0 0 0 6zM21 0l5 20H16l5-20zm43 64v-4h-4v4h-4v4h4v4h4v-4h4v-4h-4zM36 13h4v4h-4v-4zm4 4h4v4h-4v-4zm-4 4h4v4h-4v-4zm8-8h4v4h-4v-4z'/%3E%3C/g%3E%3C/svg%3E")`}
      >
        <Box
          p={1}
          w="32px"
          h="32px"
          rounded="lg"
          display="flex"
          alignItems="center"
          justifyContent="center"
          bgColor="white"
        >
          <Icon
            as={HiOutlineQuestionMarkCircle}
            color="brand.300"
            fontSize="20px"
          />
        </Box>
        <Box my={4}>
          <Text fontSize="lg" fontWeight="bold" color="brand.800">
            Need help?
          </Text>
          <Text fontSize="sm" color="white" fontWeight="semibold">
            Please contact support
          </Text>
        </Box>
        <Link
          target="_blank"
          rel="noreferrer noopener"
          href="https://intercom.help/festivalpass/en/"
          _hover={{ textDecor: 'none' }}
        >
          <Button isFullWidth color="black" bgColor="white">
            Support
          </Button>
        </Link>
      </Box>
    </Box>
  )
}

interface ItemProps {
  link: string
  match: string
  label: string
  iconColor: string
  icon: IconType
}

const SidebarItem: React.FC<ItemProps> = ({
  link,
  match,
  label,
  iconColor,
  icon,
}) => {
  const isActive = useMatch(match)

  const activeBgColor = useColorModeValue('gray.100', '#1f2733')
  const iconBgColor = useColorModeValue('gray.50', 'gray.900')
  const labelColor = useColorModeValue('gray.500', 'gray.500')
  const labelActiveColor = useColorModeValue('black', 'white')

  return (
    <GatsbyLink to={link}>
      <Box
        my={2}
        px={4}
        py={2}
        rounded="xl"
        display="flex"
        alignItems="center"
        transition="all .2s"
        boxShadow={isActive ? 'rgb(0 0 0 / 10%) 0px 4px 24px' : 'none'}
        bgColor={isActive ? activeBgColor : 'initial'}
        _hover={{ bgColor: activeBgColor }}
      >
        <Box
          w="32px"
          h="32px"
          rounded="lg"
          display="flex"
          alignItems="center"
          justifyContent="center"
          bgColor={isActive ? iconColor : iconBgColor}
        >
          <Icon
            as={icon}
            fontSize="22px"
            color={isActive ? 'white' : iconColor}
          />
        </Box>
        <Text
          ml={4}
          fontSize="sm"
          fontWeight="semibold"
          color={isActive ? labelActiveColor : labelColor}
        >
          {label}
        </Text>
      </Box>
    </GatsbyLink>
  )
}

export default Sidebar
