import React from 'react'
import { useMatch } from '@reach/router'
import { Box, Flex, useBreakpointValue } from '@chakra-ui/react'

import Protected from './protected'
import Header from './header'
import Footer from './footer'
import Sidebar from './sidebar'
import PartnerBanner from './partner-banner'
import ResponsiveBox from './responsive-box'

const Layout: React.FC = ({ children }) => {
  const isLoginPage = useMatch('/login')

  const sideWidth = useBreakpointValue({
    base: '250px',
    '2xl': '280px',
  })
  const mainWidth = useBreakpointValue({
    base: '100%',
    lg: `calc(100vw - ${sideWidth})`,
  })

  if (isLoginPage) return <>{children}</>

  return (
    <Protected>
      <Flex>
        <Box
          pl={4}
          top={0}
          left={0}
          pos="sticky"
          maxH="100vh"
          minW={sideWidth}
          display={['none', 'none', 'none', 'initial']}
        >
          <Sidebar />
        </Box>

        <Box
          w="100%"
          maxW={mainWidth}
          as="main"
          minH="100vh"
          display="flex"
          flexDir="column"
          position="relative"
        >
          <Header />
          <ResponsiveBox flex={1}>{children}</ResponsiveBox>
          <Footer />
        </Box>

        <PartnerBanner />
      </Flex>
    </Protected>
  )
}

export default Layout
