import React from 'react'
import { Box, Text, ButtonGroup, IconButton, Divider } from '@chakra-ui/react'
import {
  RiFacebookFill,
  RiSnapchatFill,
  RiInstagramFill,
  RiTwitterLine,
} from 'react-icons/ri'

import ResponsiveBox from './responsive-box'

const Footer: React.FC = () => {
  return (
    <ResponsiveBox mt={8}>
      <Divider />
      <Box
        py={[8, 8, 4]}
        display="flex"
        flexDir={['column', 'column', 'row']}
        alignItems="center"
        justifyContent="space-between"
      >
        <Text fontSize="xs">
          © {new Date().getFullYear()} Festival Pass. All rights reserved.
        </Text>
        <ButtonGroup variant="ghost" colorScheme="brand" spacing={1}>
          <IconButton
            aria-label="Festival Pass Instagram"
            icon={<RiInstagramFill size={21} />}
          />
          <IconButton
            aria-label="Festival Pass Facebook"
            icon={<RiFacebookFill size={21} />}
          />
          <IconButton
            aria-label="Festival Pass Twitter"
            icon={<RiTwitterLine size={21} />}
          />
          <IconButton
            aria-label="Festival Pass Snapchat"
            icon={<RiSnapchatFill size={21} />}
          />
        </ButtonGroup>
      </Box>
    </ResponsiveBox>
  )
}

export default Footer
