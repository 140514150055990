import React, {
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
// import {
//   Category,
//   Datasource,
// } from '../../components/EventDetailPage/EventDetailPageContent'
import { customFetch, runIfNotAborted } from '~utils/http-client'
import { useAuth } from '../auth'
import { Maybe, User } from './types'

enum Status {
  Idle,
  Loading,
  Error,
  Success,
}

interface IUserContext {
  user: User
  status: Status
  setUser: (user: User) => void
  fetchUser: () => Promise<void>
}

const UserContext = React.createContext<IUserContext | undefined>(undefined)

const UserProvider = (props: PropsWithChildren<any>) => {
  const { authData } = useAuth()
  const [user, setUser] = useState<User>()
  const [status, setStatus] = useState(Status.Idle)

  const fetchUser = useCallback(async (abortFn: Maybe<Function>) => {
    const { promise, abort } = customFetch<User>(
      `${process.env.GATSBY_STRAPI_API_URL}/users/me`,
      {
        method: 'GET',
      }
    )

    if (abortFn != null) {
      abortFn = abort
    }

    try {
      const user = await promise

      if (user) {
        setUser(user)
        setStatus(Status.Success)
      } else {
        setStatus(Status.Error)
      }
    } catch (e) {
      runIfNotAborted(e as Error, () => {
        setStatus(Status.Error)
      })
    }
  }, [])

  useEffect(() => {
    let abortFn: Maybe<Function> = null

    const allowGetUser =
      authData != null && (user == null || user.id !== authData.userId)

    if (allowGetUser) {
      setStatus(Status.Loading)
      fetchUser(abortFn)
    }

    return () => {
      if (abortFn != null) {
        abortFn()
      }
    }
  }, [authData, fetchUser, user])

  useEffect(() => {
    if (!authData && user) setUser(undefined)
  }, [authData])

  const value = useMemo(
    () => ({ user, fetchUser, setUser, status }),
    [status, fetchUser, user]
  )

  return <UserContext.Provider value={value} {...props} />
}

const useUser = (): IUserContext => {
  const context = useContext(UserContext)
  if (context === undefined) {
    throw new Error('useUser must be used within an UserProvider')
  }
  return context
}

export { useUser, UserProvider }
