import React from 'react'
import { Link } from 'gatsby'
import { Box, Image, ImageProps, useColorModeValue } from '@chakra-ui/react'

import LightFullLogo from '~images/logos/fp-full-logo-white.svg'
import DarkFullLogo from '~images/logos/fp-full-logo-black.svg'

const LogoFull: React.FC<ImageProps> = props => {
  const logo = useColorModeValue(DarkFullLogo, LightFullLogo)

  return (
    <Box>
      <Link to="/">
        <Image src={logo} w="168px" {...props} alt="Festival Pass Logo" />
      </Link>
    </Box>
  )
}

export default LogoFull
