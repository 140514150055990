import React, { useEffect } from 'react'
import { stringifyUrl } from 'query-string'
import { useLocation, navigate } from '@reach/router'
import { Tag, Center, Progress, VStack, useToast } from '@chakra-ui/react'
import { HiOutlineLockClosed } from 'react-icons/hi'

import Logo from './logo'
import { useAuth } from '~context/auth'
import { useUser } from '~context/user'

const Protected: React.FC = ({ children }) => {
  const { pathname, search } = useLocation()
  const { isAuthenticated } = useAuth()
  const { user } = useUser()

  const toast = useToast()
  const toastId = 'auth-redirect-warning'
  const hasSufficientRole = ['festivalpartner', 'administrator'].includes(
    user?.role.type
  )

  useEffect(() => {
    if (!isAuthenticated) {
      if (!toast.isActive(toastId)) {
        toast({
          id: toastId,
          isClosable: true,
          variant: 'subtle',
          description: 'Please log in to continue',
        })
      }

      navigate(
        stringifyUrl({
          url: '/login',
          query: { redirect: pathname + search },
        }),
        { replace: true }
      )
    }
  }, [isAuthenticated])

  if (!user || !isAuthenticated)
    return (
      <Center w="100vw" h="100vh">
        <VStack spacing={6}>
          <Logo w="100px" filter="opacity(.8)" />
          <Progress
            h="4px"
            w="120px"
            rounded="lg"
            isIndeterminate
            colorScheme="brand"
          />
        </VStack>
      </Center>
    )

  if (!hasSufficientRole)
    return (
      <Center w="100vw" h="100vh" p={6} textAlign="center">
        <VStack spacing={4}>
          <HiOutlineLockClosed size="32px" />
          <Tag variant="subtle" colorScheme="red">
            Insufficient Permission
          </Tag>
        </VStack>
      </Center>
    )

  return <>{children}</>
}

export default Protected
