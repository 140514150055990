import React from 'react'
import {
  Box,
  Text,
  Image,
  Button,
  Avatar,
  Switch,
  Divider,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  useColorModeValue,
  useColorMode,
} from '@chakra-ui/react'
import { navigate } from '@reach/router'
import { HiOutlineUserCircle, HiLogout } from 'react-icons/hi'

import { useAuth } from '~context/auth'
import { useUser } from '~context/user'

const ProfileButton: React.FC = () => {
  const { user } = useUser()
  const { logout } = useAuth()
  const { colorMode, toggleColorMode } = useColorMode()

  const name = `${user.first_name} ${user.last_name}`
  const borderColor = useColorModeValue('brand.100', 'gray.700')

  return (
    <Popover placement="bottom-end">
      <PopoverTrigger>
        <Avatar
          showBorder
          borderColor={borderColor}
          name={name}
          src={user.profile_img?.url}
          cursor="pointer"
          as={Button}
          p={0}
        />
      </PopoverTrigger>
      <PopoverContent w="250px" rounded="xl">
        <PopoverBody px={0}>
          <Box px={4} py={1}>
            {user.primary_badge?.icon?.url && (
              <Image
                mb={1}
                w="32px"
                h="32px"
                rounded="lg"
                src={user.primary_badge.icon.url}
              />
            )}
            <Text fontWeight="bold" isTruncated>
              {name}
            </Text>
            <Text fontSize="sm">
              {user.credit_balance.toLocaleString()} credits
            </Text>
            <Text fontSize="sm" isTruncated>
              {user.primary_badge?.name}
            </Text>
          </Box>
          <Divider my={2} />
          <Box>
            <PopoverMenuItem
              label="Dark theme"
              handleClick={toggleColorMode}
              rightElement={
                <Switch colorScheme="brand" isChecked={colorMode === 'dark'} />
              }
            />
            <PopoverMenuItem
              label="Account"
              handleClick={() => navigate('/account')}
              rightElement={<HiOutlineUserCircle size="20px" />}
            />
            <PopoverMenuItem
              label="Log out"
              handleClick={logout}
              rightElement={<HiLogout size="20px" />}
            />
          </Box>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  )
}

interface ItemProps {
  label: string
  handleClick?: () => void
  rightElement?: React.ReactElement
}

const PopoverMenuItem: React.FC<ItemProps> = ({
  label,
  handleClick,
  rightElement,
}) => {
  return (
    <Box
      py={2}
      px={4}
      as={Button}
      isFullWidth
      fontWeight="normal"
      rounded="none"
      variant="ghost"
      cursor="pointer"
      justifyContent="space-between"
      onClick={handleClick}
    >
      <Text>{label}</Text>
      {rightElement}
    </Box>
  )
}

export default ProfileButton
