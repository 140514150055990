import React, { useMemo } from 'react'
import fetch from 'isomorphic-fetch'
import {
  ApolloProvider as Provider,
  ApolloClient,
  HttpLink,
  InMemoryCache,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'

import { useAuth } from './auth'

const ApolloProvider: React.FC = ({ children }) => {
  const { authData } = useAuth()

  const client = useMemo(() => {
    const httpLink = new HttpLink({
      fetch,
      uri: `${process.env.GATSBY_STRAPI_API_URL}/graphql`,
    })

    const authLink = setContext((_, { headers }) => {
      if (authData)
        return {
          headers: { ...headers, authorization: `Bearer ${authData.jwt}` },
        }
      return { headers }
    })

    return new ApolloClient({
      link: authLink.concat(httpLink),
      cache: new InMemoryCache(),
    })
  }, [authData])

  return <Provider client={client}>{children}</Provider>
}

export default ApolloProvider
