import React, {
  useState,
  useRef,
  useMemo,
  useEffect,
  useCallback,
  useContext,
} from 'react'
import decode from 'jwt-decode'
import get from 'lodash/get'

import * as http from '~utils/http-client'
import * as storage from '~utils/web-storage'
import { AuthData, AuthResponse, JwtDecoded } from '~context/auth/types'

interface IAuthContext {
  authData: AuthData | undefined
  isAuthenticated: boolean
  isAuthenticating: boolean
  login: (
    email: string,
    password: string
  ) => Promise<{ success: boolean; errorMessage?: string }>
  logout: () => void
}

const updateHttpAuthHeader = (authData: AuthData | undefined) => {
  if (authData) {
    http.setDefaultHeader('Authorization', `Bearer ${authData.jwt}`)
  } else {
    http.clearDefaultHeader('Authorization')
  }
}

const localStorageKey = 'fp__AuthProvider_authData'
const AuthContext = React.createContext<IAuthContext | undefined>(undefined)

export const AuthProvider: React.FC = props => {
  const [authData, setAuthData] = useState<AuthData | undefined>(() => {
    const persistedAuthData =
      storage.loadFromLocalStorage<AuthData>(localStorageKey)
    updateHttpAuthHeader(persistedAuthData)
    return persistedAuthData
  })
  const isAuthenticating = useRef(false)

  const updateAuthData = (newAuthData?: AuthData) => {
    storage.saveInLocalStorage(localStorageKey, newAuthData)
    updateHttpAuthHeader(newAuthData)
    setAuthData(newAuthData)
  }

  const handleAuthFetch = useCallback(
    async (
      endpoint: string,
      options: RequestInit
    ): Promise<{
      success: boolean
      errorMessage: string | undefined
    }> => {
      try {
        isAuthenticating.current = true

        const { promise } = http.customFetch<AuthResponse>(endpoint, options)
        const { jwt } = await promise
        const { id: userId, exp: jwtExpiry } = decode(jwt) as JwtDecoded
        const newAuthData: AuthData = {
          userId,
          jwt,
          jwtExpiry,
        }

        isAuthenticating.current = false
        updateAuthData(newAuthData)

        return { success: newAuthData != null, errorMessage: undefined }
      } catch (e) {
        isAuthenticating.current = false

        return {
          success: false,
          errorMessage: get(e, 'errorData.message[0].messages[0].message'),
        }
      }
    },
    []
  )

  const login = useCallback(
    async (
      email: string,
      password: string
    ): Promise<{ success: boolean; errorMessage: string | undefined }> =>
      handleAuthFetch(`${process.env.GATSBY_STRAPI_API_URL}/auth/local`, {
        method: 'POST',
        body: http.json({
          identifier: email,
          password,
        }),
      }),
    [handleAuthFetch]
  )

  const logout = useCallback(() => {
    if (authData != null) {
      updateAuthData()
    }
  }, [authData])

  const refreshJwt = useCallback(() => {
    isAuthenticating.current = true

    try {
      if (authData != null) {
        logout() // Temporary instead of what's below

        // TODO: implement backend endpoint: https://www.youtube.com/watch?v=0hAmccuaK5Q
        // const newAuthData = await requestNewToken();
        // updateAuthData(newAuthData);
      }
    } catch (e) {
      logout()
      throw e
    } finally {
      isAuthenticating.current = false
    }
  }, [authData, logout])

  // this effect is responsible for refreshing the jwt before it expires
  useEffect(() => {
    let timer: NodeJS.Timeout

    if (authData != null) {
      const millisecondsToExpiry = authData.jwtExpiry * 1000 - Date.now()
      const millisecondsToRefresh = millisecondsToExpiry - 30000

      // The setTimeout delay is a 32 bit integer, so the callback runs immediately if the delay overflows 24.855 days.
      var maxAllowedSetTimeoutDelay = Math.pow(2, 31) - 1

      if (millisecondsToRefresh <= 0) {
        refreshJwt()
      } else if (millisecondsToRefresh < maxAllowedSetTimeoutDelay) {
        timer = setTimeout(() => {
          refreshJwt()
        }, millisecondsToRefresh)
      }
    }

    return () => {
      if (timer != null) {
        clearTimeout(timer)
      }
    }
  }, [authData, refreshJwt])

  // Listen to changes in local storage in order to adapt to actions from other browser tabs
  useEffect(() => {
    const handleChange = () => {
      updateAuthData(storage.loadFromLocalStorage(localStorageKey))
    }
    window.addEventListener('storage', handleChange, false)
    return () => {
      window.removeEventListener('storage', handleChange)
    }
  }, [])

  const resetHttpAuthHeader = useCallback(() => {
    updateHttpAuthHeader(authData)
  }, [authData])

  const isAuthenticated = authData != null
  const value = useMemo(
    () => ({
      authData,
      isAuthenticated,
      isAuthenticating: isAuthenticating.current,
      login,
      // register,
      logout,
      resetHttpAuthHeader,
    }),
    [
      authData,
      isAuthenticated,
      isAuthenticating,
      login,
      // register,
      logout,
      resetHttpAuthHeader,
    ]
  )

  return <AuthContext.Provider value={value} {...props} />
}

export const useAuth = () => {
  const context = useContext(AuthContext)
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider')
  }
  return context
}
