import React, { useEffect } from 'react'
import {
  Box,
  HStack,
  IconButton,
  Drawer,
  DrawerBody,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
  useColorModeValue,
} from '@chakra-ui/react'
import { globalHistory } from '@reach/router'
import { HiOutlineMenu } from 'react-icons/hi'

import Logo from '../logo'
import Sidebar from '../sidebar'
import ProfileButton from './profile-button'
import ResponsiveBox from '../responsive-box'

const Header: React.FC = () => {
  const { isOpen, onClose, onToggle } = useDisclosure()
  const bgColor = useColorModeValue('white', 'gray.900')

  useEffect(() => {
    return globalHistory.listen(({ action }) => {
      if (action === 'PUSH') onClose()
    })
  }, [])

  return (
    <>
      <ResponsiveBox
        py={[4, 4, 4, 6]}
        pos="sticky"
        top={0}
        right={0}
        zIndex={1}
        bgColor={bgColor}
        transition="all .2s"
      >
        <Box
          display="flex"
          flexDir="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <HStack>
            <Logo display={['initial', 'initial', 'initial', 'none']} />
          </HStack>
          <HStack spacing={0}>
            <IconButton
              pr="0px"
              mr={[1, 2]}
              variant="ghost"
              aria-label="Toggle sidebar"
              display={['flex', 'flex', 'flex', 'none']}
              icon={<HiOutlineMenu size="26px" />}
              onClick={onToggle}
            />
            <ProfileButton />
          </HStack>
        </Box>
      </ResponsiveBox>

      <Drawer placement="left" isOpen={isOpen} onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent m={4} maxW="300px" rounded="2xl" bgColor={bgColor}>
          <DrawerCloseButton top={4} right={4} />
          <DrawerBody px={4}>
            <Sidebar />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  )
}

export default Header
