import React from 'react'
import { ChakraProvider } from '@chakra-ui/react'
import { QueryClient, QueryClientProvider } from 'react-query'

import theme from '~utils/theme'
import ApolloProvider from './apollo-provider'
import { AuthProvider } from './auth'
import { UserProvider } from './user'
import { ScrollProvider } from './scroll-provider'

const reactQueryClient = new QueryClient()

const AppContext: React.FC = ({ children }) => {
  return (
    <ChakraProvider theme={theme}>
      <QueryClientProvider client={reactQueryClient}>
        <AuthProvider>
          <ApolloProvider>
            <UserProvider>
              <ScrollProvider>{children}</ScrollProvider>
            </UserProvider>
          </ApolloProvider>
        </AuthProvider>
      </QueryClientProvider>
    </ChakraProvider>
  )
}

export default AppContext
